exports.components = {
  "component---src-pages-12-months-electric-h-2-24-tsx": () => import("./../../../src/pages/12-months-electric-h2-24.tsx" /* webpackChunkName: "component---src-pages-12-months-electric-h-2-24-tsx" */),
  "component---src-pages-12-months-free-h-2-tsx": () => import("./../../../src/pages/12-months-free-h2.tsx" /* webpackChunkName: "component---src-pages-12-months-free-h-2-tsx" */),
  "component---src-pages-12-months-free-q-1-24-tsx": () => import("./../../../src/pages/12-months-free-q1-24.tsx" /* webpackChunkName: "component---src-pages-12-months-free-q-1-24-tsx" */),
  "component---src-pages-12-months-free-q-2-tsx": () => import("./../../../src/pages/12-months-free-q2.tsx" /* webpackChunkName: "component---src-pages-12-months-free-q-2-tsx" */),
  "component---src-pages-12-months-free-tsx": () => import("./../../../src/pages/12-months-free.tsx" /* webpackChunkName: "component---src-pages-12-months-free-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-driveaway-tsx": () => import("./../../../src/pages/driveaway.tsx" /* webpackChunkName: "component---src-pages-driveaway-tsx" */),
  "component---src-pages-electric-tsx": () => import("./../../../src/pages/electric.tsx" /* webpackChunkName: "component---src-pages-electric-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */)
}

